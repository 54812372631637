import React from 'react';
import ApplicationTile from './ApplicationTile';
import loadAppIcon from '../../utils/loadAppIcon';
import { useGetUserInfo, useGetAppSettings } from '../../hooks';

const AppDisplay = () => {
    const {
        data: settings,
        isLoading: isLoadingSettings,
        isError: errorSettings
    } = useGetAppSettings({});

    const {
        data: userInfo,
        isLoading: isLoadingUserInfo,
        isError: isErrorUserInfo
    } = useGetUserInfo({});

    const generateAppTiles = (roles) => {
        let appList = [];

        if (roles && settings.appInfoList && Object.keys(settings.appInfoList).length > 0) {
            roles.sort();

            for (let i = 0;  i < roles.length; i++) {
                if (!roles[i - 1] !== undefined && roles[i].includes(roles[i - 1])) {
                    roles.splice(i - 1, 1);
                }
            }

            settings.appInfoList.map((appInfo, index) => {
                roles.map((role, index) => {
                    appInfo.roles.find(appRole => {
                        if (appRole.includes(role)) {
                            appList.push(
                                <ApplicationTile 
                                    key={appInfo.name}
                                    appTitle={appInfo.name}
                                    appIcon={loadAppIcon(appInfo.appIcon.image, appInfo.appIcon.width, appInfo.appIcon.height)}
                                    appUrl={appInfo.url}
                                    isAdmin={role.includes('Admin') && !appInfo.name.includes('HSI-T21') ? true : false}
                                    guide={appInfo.guide}
                                    guideUrl={appInfo.guideUrl}
                                />
                            )
                        }
                        return null;
                    })
                    return null;
                })
                return null;
            });
        }

        for (let i = 0; i < appList.legnth; i++) {
            if (appList[i - 1] !== undefined && appList[i].props.appTitle.includes(appList[i - 1].props.appTitle)) {
                appList.splice(i - 1, 1);
            }
        }

        let appMap = new Map();

        appList.forEach((app) => appMap.set(app.props.appTitle, app));

        const newAppList = Array.from(appMap.values());

        return newAppList;
    }
    return (
        <div className="app-display">
            <div className="row app-disp-title">WELCOME TO THE DEA PORTAL</div>
            {(settings?.announcementText1.length > 0 || settings?.announcementText2.length > 0) &&
                (<div className="row app-disp-announcement-container">
                    {settings?.announcementText1.length > 0 &&
                        <div className="row app-disp-announcement-text">
                            {settings?.announcementText1}
                        </div>
                    }
                    {settings?.announcementText2.length > 0 &&
                        <div className="row app-disp-announcement-text">
                            {settings?.announcementText2}
                        </div>
                    }
                </div>)
            }
            <div className="row app-disp-container">
                {/* <ApplicationTile 
                    appTitle='DICE'
                    appIcon={loadAppIcon('DiceIcon.svg', 75, 85)}
                    guide={true} 
                    guideUrl='http://google.com'/>
                <ApplicationTile 
                    appTitle='T21 Coordination Nightly'
                    appIcon={loadAppIcon('HSILogo.jpg', 110, 61.62)}
                    guide={true} 
                    guideUrl='http://google.com'/> */}
                {!isLoadingUserInfo && !isLoadingSettings && generateAppTiles(userInfo?.roles)}
            </div>
        </div>
    );
}

export default AppDisplay;