import React from 'react';
import { useGetAppSettings } from '../hooks';

const LogoutSuccess = () => {
    const {
        data: settings,
        isLoading: isLoadingSettings,
        isError: isErrorSettings
    } = useGetAppSettings({});

    return (
        <div className="row middle-lg center-lg error-main" >
            <div style={{ width: '50%'}}>
                <div className="row" style={{fontSize: "2rem", marginBotton: 'rem', textAlign:"center"}}>
                    You are not logged in. Please close your browser to ensure secure logout.
                </div>
                <br/>
                <div className="row center-lg" style={{ fontSize: "1.5rem"}}>
                    If you need further assistance, please contact the EPIC Help Desk.
                </div>
                <hr />
                <div className="row center-lg" style={{ fontSize: '1.5rem', marginTop: '1rem', width: '100%'}}>Phone: 1-571-387-4024 <span style={{marginLeft: '5rem'}}>Email: EPIChelpdesk@dea.gov</span></div>
            </div>
        </div>
    );
}

export default LogoutSuccess;